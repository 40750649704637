import "@ryangjchandler/spruce";
import "alpine-turbo-drive-adapter";
import "alpinejs";

import Rails from "@rails/ujs";
import consumer from "../../channels/consumer";
import { cloneDeep } from "lodash";

import numeral from "../numeral";

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("#ambiente").forEach((el) => {
    const ambiente = el.dataset.ambiente;
    Spruce.store("bilhete").ambiente = ambiente;
    Spruce.store("jogo").ambiente = ambiente;


    consumer.subscriptions.subscriptions.forEach((subscription) => {
      consumer.subscriptions.remove(subscription);
    });

    if (ambiente == "prejogo") {
      consumer.subscriptions.create("JogoChannel", {
        received(data) {
          if ("jogo" in data) {
            Spruce.store("jogo").atualizaJogo(data.jogo);
          }
          if ("odd" in data) {
            Spruce.store("jogo").atualizaOdd(data.odd);
          }
        },
      });
      
    } else if (ambiente == "aovivo") {
      consumer.subscriptions.create("JogoAoVivoChannel", {
        received(data) {
          if ("jogos" in data) {
            Spruce.store("jogo").atualizaJogoAoVivo(data.jogos);
            // data.jogos.forEach((jogo) => {
            //   Spruce.store("jogo").atualizaJogo(jogo);
            // });
          }
          if ("odds" in data) {
            Spruce.store("jogo").atualizaOddAoVivo(data.odds);
            // data.odds.forEach((odd) => {
            //   Spruce.store("jogo").atualizaOdd(odd);
            // });
          }
        },
      });
    }
  });
}); // DOMCOntentLoaded

Spruce.store(
  "local",
  {
    valorAposta: 10,

    getValorAposta(prefixo = "") {
      return prefixo + numeral(this.valorAposta).format();
    },

    setValorAposta(e) {
      this.valorAposta = numeral(e.target.value).value();
    },
  },
  true
);

Spruce.store("jogo", () => {
  return {
    jogos: [],
    modalidades: {},
    modalidadesReverse: {},

    tipoApostasSelecionado: { partida: {}, odds: [] },
    pesquisa: "",

    ambiente: "",

    init(jogos) {
      const self = this;
      jogos = typeof jogos === "string" ? JSON.parse(jogos) : jogos;
      this.jogos = jogos;
      this.modalidades = {};
      this.modalidadesReverse = {};
      this.page = 1;

      // Scroll Paginate
      const jogoObserver = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.paginarJogo();
            }
          });
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.25,
        }
      ).observe(document.getElementById("paginarJogo"));

      const oddsObserver = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.paginarOdd();
            }
          });
        },
        {
          root: document.getElementById("modalTipoApostaBody"),
          rootMargin: "0px",
          threshold: 0.25,
        }
      ).observe(document.getElementById("paginarOdd"));


      // Scroll Paginate
    },

    /**
     * Substitui os jogos de tela;
     * Atualiza os jogos do bilhete;
     * @param {*} jogos
     */
    atualizaJogoAoVivo(jogos) {
      this.jogos = jogos;

      const jogosId = Spruce.store("bilhete")
        .apostas.filter((f) => f.ambiente == "aovivo")
        .map((m) => m.jogo_id);

      const jogosBilhete = jogos.filter((f) => jogosId.includes(f.jogo_id));

      jogosBilhete.forEach((jogo) => {
        Spruce.store("bilhete").atualizaCotacao(jogo);
      });
    },

    /**
     * @param [{*}] odds
     */
    atualizaOddAoVivo(odds) {
      const oddsSelecionado = odds.filter(
        (odd) => odd.jogo_id == this.tipoApostasSelecionado.partida.jogo_id
      );

      if (oddsSelecionado) {
        this.tipoApostasSelecionado.odds = oddsSelecionado;
      }

      // pega odds do bilhete
      const modalidadesId = Spruce.store("bilhete")
        .apostas.filter((f) => f.ambiente == "aovivo")
        .map((m) => m.modalidade_id);

      // filtra as novas odds pela odd do bilhete
      const modalidadesBilhete = odds.filter((f) =>
        modalidadesId.includes(f.modalidade_id)
      );

      // atualiza odd do bilhete com nova cotacao
      modalidadesBilhete.forEach((odd) => {
        Spruce.store("bilhete").atualizaCotacao(odd);
      });
    },

    atualizaJogo(jogo) {
      const idx = this.jogos.findIndex((j) => {
        return j.jogo_id == jogo.jogo_id;
      });

      if (idx > -1) {
        this.jogos.splice(idx, 1, jogo);
        Spruce.store("bilhete").atualizaCotacao(jogo);
      }
    },

    atualizaOdd(odd) {
      if (this.tipoApostasSelecionado.partida.jogo_id == odd.jogo_id) {
        const idx = this.tipoApostasSelecionado.odds.findIndex((j) => {
          return j.jogo_id == odd.jogo_id;
        });

        if (idx > -1) {
          this.tipoApostasSelecionado.odds.splice(idx, 1, odd);
          Spruce.store("bilhete").atualizaCotacao(odd);
        }
      }
    },

    paginarJogo() {
      const self = this;
      this.page = this.page + 1;
      const params = new URLSearchParams({ page: this.page });
      
      if (this.ambiente == "aovivo"){
        return null;
      }

      fetch(`/jogo.json?${params}`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": Rails.csrfToken(),
        },
        credentials: "same-origin",
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          self.jogos = cloneDeep(self.jogos).concat(data);
        });
    },

    paginarOdd() {
      const self = this;
      if (self.tipoApostasSelecionado.odds.length == 0) return;
      const { partida } = self.tipoApostasSelecionado;
      self.pageOdd = self.pageOdd + 1;
      const params = new URLSearchParams({
        jogo_id: partida.jogo_id,
        page: self.pageOdd,
      });
      fetch(`/carregar-jogo?${params}`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": Rails.csrfToken(),
        },
        credentials: "same-origin",
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          self.tipoApostasSelecionado.odds = cloneDeep(
            self.tipoApostasSelecionado.odds
          ).concat(data);
        });
    },

    getPartidas() {
      if (this.pesquisa.trim().length == 0) {
        return this.jogos;
      }

      const pesquisa = this.pesquisa.toUpperCase();

      return this.jogos.reduce((m, v) => {
        if (
          v.nome_casa.toUpperCase().includes(pesquisa) ||
          v.nome_fora.toUpperCase().includes(pesquisa) ||
          v.data.toUpperCase().includes(pesquisa) ||
          v.hora.toUpperCase().includes(pesquisa)
        ) {
          m.push(v);
        }
        return m;
      }, []);
    },

    getModalidade(m) {
      return this.modalidades[m];
    },

    getValorFormatado(v) {
      return numeral(parseFloat(v)).format();
    },

    isZero(v) {
      return parseFloat(v) == 0;
    },

    getDataOuHoraFormatada(d, h) {
      //GAMBIARRA PARA FUNCIONAR A DATA
      var today = new Date();
      var dd = today.getDate();

      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = '0' + dd;
      }

      if (mm < 10) {
        mm = '0' + mm;
      }

      today = dd + '/' + mm + '/' + yyyy;
      if (d == today) {
        return h;
      }
      return moment(d, "DD/MM/YYYY").format("DD/MM");
    },

    getHoraFormatada(v) {
      return moment(v).format("DD/MM/YYYY HH:mm");
    },

    getModalidadeById(modalidade_id) {
      return this.modalidadesReverse[modalidade_id];
    },

    modalTipoAposta(partida) {
      this.pageOdd = 1;
      this.tipoApostasSelecionado = {
        partida: {},
        odds: [],
      };

      const bs = new bootstrap.Modal(
        document.getElementById("modalTipoAposta"),
        {}
      );

      let url = `/carregar-jogo`
      if (this.ambiente == "aovivo") {
        url = `/viv/carregar-jogo`
      }

      Rails.ajax({
        type: "GET",
        url: url,
        data: new URLSearchParams({ jogo_id: partida.jogo_id }).toString(),
        success: (resp) => {
          this.tipoApostasSelecionado = {
            partida: partida,
            odds: resp,
          };
        },
        error: (resp) => {
          console.log("não foi possível carregar as odds", resp);
        },
      });

      bs.show();
    },
  };
});

Spruce.store(
  "bilhete",
  () => {
    return {
      apostas: [],
      ambiente: "",

      setApostas(apostas) {
        let outras = cloneDeep(
          this.apostas.filter((a) => a.ambiente != this.ambiente)
        );
        this.apostas = outras.concat(apostas);
      },

      getApostas() {
        return this.apostas.filter((a) => a.ambiente == this.ambiente);
      },

      getQtdTotal() {
        return this.getApostas().length;
      },

      getVlrRetorno() {
        let valorAposta =
          this.getApostas().length > 0 ? Spruce.store("local").valorAposta : 0;
        let total = this.getVlrCotacao(false) * valorAposta;
        return numeral(total).format();
      },

      getVlrCotacao(formatado = true) {
        let total = this.getApostas().reduce((m, aposta) => {
          let vlr = aposta.modalidade.cotacao;
          return m * parseFloat(vlr);
        }, 1);

        if (formatado) {
          return numeral(total).format();
        }

        return total;
      },

      temApostaIndex(jogoId, modalidadeId) {
        return this.apostas.findIndex(
          (f) => f.jogo_id == jogoId && f.modalidade_id == modalidadeId
        );
      },

      temAposta(jogoId, modalidadeId) {
        return this.temApostaIndex(jogoId, modalidadeId) > -1;
      },

      trocarAposta(
        partida,
        modalidadeId,
        modalidade = "",
        cotacao = 0,
        tipoModalidade = ""
      ) {
        //remove caso já exista (troca)
        let apostas = cloneDeep(this.getApostas());
        const idx = apostas.findIndex(
          (f) => f.jogo_id == partida.jogo_id && f.modalidade_id == modalidadeId
        );
        if (idx > -1) {
          apostas.splice(idx, 1);
        } else {
          // mantem uma posta por jogo
          apostas = apostas.filter((f) => f.jogo_id != partida.jogo_id);
          apostas.push({
            ambiente: this.ambiente,
            jogo_id: partida.jogo_id,

            modalidade_id: modalidadeId,
            modalidade: this._getModalidade(partida, modalidadeId, {
              modalidade,
              modalidade_id: modalidadeId,
              cotacao,
              tipo_modalidade: tipoModalidade,
            }),
            partida: cloneDeep(partida),
          });
        }
        this.setApostas(apostas);
      },

      atualizaCotacao(jogoOuOdd) {
        let idx = -1;
        if (
          "casa" in jogoOuOdd &&
          "empate" in jogoOuOdd &&
          "fora" in jogoOuOdd
        ) {
          // jogo
          idx = this.temApostaIndex(
            jogoOuOdd.jogo_id,
            jogoOuOdd.casa.modalidade_id
          );
          if (idx > -1) {
            this.apostas[idx].modalidade.cotacao = jogoOuOdd.casa.cotacao;
            this.apostas[idx].partida.casa.cotacao = jogoOuOdd.casa.cotacao;
            return true;
          }

          idx = this.temApostaIndex(
            jogoOuOdd.jogo_id,
            jogoOuOdd.empate.modalidade_id
          );
          if (idx > -1) {
            this.apostas[idx].modalidade.cotacao = jogoOuOdd.empate.cotacao;
            this.apostas[idx].partida.empate.cotacao = jogoOuOdd.empate.cotacao;
            return true;
          }

          idx = this.temApostaIndex(
            jogoOuOdd.jogo_id,
            jogoOuOdd.fora.modalidade_id
          );
          if (idx > -1) {
            this.apostas[idx].modalidade.cotacao = jogoOuOdd.fora.cotacao;
            this.apostas[idx].partida.fora.cotacao = jogoOuOdd.fora.cotacao;
            return true;
          }
        } else if ("cotacao" in jogoOuOdd && "modalidade_id" in jogoOuOdd) {
          //odd
          idx = this.temApostaIndex(jogoOuOdd.jogo_id, jogoOuOdd.modalidade_id);
          if (idx > -1) {
            this.apostas[idx].modalidade.cotacao = jogoOuOdd.cotacao;
            return true;
          }
        }
        return false;
      },

      _getModalidade(partida, modalidadeId, modalidadeDefault = {}) {
        let modalidade = {}; // modalidade:, modalidade_id:, cotacao:

        switch (modalidadeId) {
          case partida.casa.modalidade_id:
            modalidade = partida.casa;
            modalidade["tipo_modalidade"] = "Vencedor do Encontro";
            break;

          case partida.empate.modalidade_id:
            modalidade = partida.empate;
            modalidade["tipo_modalidade"] = "Vencedor do Encontro";
            break;

          case partida.fora.modalidade_id:
            modalidade = partida.fora;
            modalidade["tipo_modalidade"] = "Vencedor do Encontro";
            break;

          default:
            // debugger;
            if (modalidadeDefault) {
              modalidade = modalidadeDefault;
            }
            break;
        }

        return modalidade;
      },

      finalizarAposta() {
        const self = this;
        let finalizar_aposta = $('#finalizar_aposta');
        finalizar_aposta.prop("disabled", true);
        if (this.apostas.length == 0) {
          return false;
        }
        let apostas = [];
        if (this.ambiente == "prejogo") {
          apostas = this.apostas.reduce((m, v) => {
            let dtJogo = moment(
              `${v.partida.data} ${v.partida.hora}`,
              "DD/MM/YYYY HH:mm"
            );

            if (moment() < dtJogo) {
              m.push({
                jogo_id: v.jogo_id,
                modalidade_id: v.modalidade_id,
                ambiente: v.ambiente
              });
            }
            return m;
          }, []);
        }  else {
          apostas = this.apostas.map((m) => {
            return {
              jogo_id: m.jogo_id,
              modalidade_id: m.modalidade_id
            }
          });
        }

        let aposta = {
          valor: Spruce.store("local").valorAposta,
          jogos: apostas,
        };

        let chamada = "/finalizar-aposta.json";
        if (this.ambiente == "aovivo") {
          chamada = "/finalizar-aposta-aovivo.json";
        }

        fetch(chamada, {
          method: "post",
          body: JSON.stringify({ aposta: aposta }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": Rails.csrfToken(),
          },
          credentials: "same-origin",
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.status == "ok") {
              notyf.success("Confira seu bilhete");
              bootstrap.Modal.getInstance(
                document.getElementById("modalBilhete")
              ).hide();
              self.apostas = [];
              // ver para deixar em um método separado

              new Promise((resolve, reject) => {
                gtag('event', 'purchase', {
                  'transaction_id': data.cupom_premio,
                  'value': data.valor,
                  'event_label': 'aposta',
                  'affiliation': data.origem,
                  "currency": "BRL"
                });
                resolve()
              })
                .then(() => {
                  window.location = `/b/${data.cupom_premio}`;
              });
              

              
            } else {
              finalizar_aposta.prop("disabled", false);
              notyf.error(data.errors.base[0]);
            }
          });
      },
    };
  },
  true
);
