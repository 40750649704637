import { Controller } from "stimulus"
import $ from 'jquery'

require ("select2/dist/css/select2")
require ("select2-bootstrap-theme/dist/select2-bootstrap")

import Select2 from "select2"

export default class extends Controller {
  connect() {
    $('.buscar_admin_jogador').select2({
        theme: "bootstrap",
        language: "pt-BR",
        minimumInputLength: 2,
        placeholder: 'Buscar',
        dataType: 'json',
        ajax: {
            url: '/buscar_jogador',
            data: function(params) {
                var query = {
                    search: params.term
                }

                return query
            },
            processResults: function(data) {
                // Transforms the top-level key of the response object from 'items' to 'results'
                console.log(data)
                return {
                    results: data
                };
            }

        }
    });

      $('.buscar_jogador_banca').select2({
          theme: "bootstrap",
          language: "pt-BR",
          minimumInputLength: 2,
          placeholder: 'Buscar',
          dataType: 'json',
          ajax: {
              url: '/enviar_creditos/buscar-jogadores-banca',
              data: function (params) {
                  var query = {
                      search: params.term
                  }

                  return query
              },
              processResults: function (data) {
                  // Transforms the top-level key of the response object from 'items' to 'results'
                  console.log(data)
                  return {
                      results: data
                  };
              }

          }
      });
  }
}
