// App.cashway_pix = App.cable.subscriptions.create("CashwayPixChannel", {
//   connected: function() {
//     // Called when the subscription is ready for use on the server
//   },

//   disconnected: function() {
//     // Called when the subscription has been terminated by the server
//   },

//   received: function(data) {
//     $('#' + data.cashwayPixId).html(data.html);
//   }
// });



import consumer from "../channels/consumer";

consumer.subscriptions.create("CashwayPixChannel", {
  received(data) {
    $('#' + data.cashwayPixId).html(data.html); 
  },
});